import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { convertToTotalMinutes } from './MeanDailyUsage';
import CustomTooltip from '../CustomTooltip';
import '../style.scss';
import { UnloadPerDay } from '../types';

interface Props {
  unloadPerDay: UnloadPerDay[] | undefined;
  loading: boolean;
}

const DailyUnload: React.FC<Props> = ({ unloadPerDay, loading }): JSX.Element => {
  const { t } = useTranslation();
  const [dailyUnloadData, setDailyUnloadData] = useState<UnloadPerDay[]>([]);
  const [noData, setNoData] = useState<boolean>(false);

  useEffect(() => {
    if (unloadPerDay) {
      setNoData(false);
      if (unloadPerDay.length === 0) {
        setNoData(true);
        return;
      }
      const formattedData = unloadPerDay
        .map((item: UnloadPerDay) => {
          const date = parseISO(item.date);
          const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
          return {
            date: format(localDate, 'd MMM'),
            originalDate: localDate,
            value: convertToTotalMinutes({ days: 0, hours: 0, minutes: item.value, seconds: 0 }),
          };
        })
        .sort((a, b) => a.originalDate.getTime() - b.originalDate.getTime());

      setDailyUnloadData(formattedData);
    }
  }, [unloadPerDay]);

  return (
    <div className="daily-unload-container">
      <div className="componentBase ">
        <div
          className="info-icon"
          title={`${t('meanDailyUnload')} \n\n${t('meanDailyUnloadCalculus')}`}
        >
          <FontAwesomeIcon icon={faInfo} />
        </div>
        <div className="daily-unload-title">
          <h5>{t('dailyUnload')}</h5>
          <div style={{ fontSize: '9px', fontWeight: 'bold', color: '#b1b1b1' }}>
            ({t('thisWeek')})
          </div>
        </div>
        <hr className="separator" />
        {loading && (
          <div className="spinner" style={{ paddingBottom: '9vh' }}>
            <FontAwesomeIcon icon={faSpinner} style={{ fontSize: '38px', color: 'gray' }} spin />
          </div>
        )}
        {!loading && !noData && (
          <ResponsiveContainer width="95%" height="72%" className="pt-4">
            <BarChart
              data={dailyUnloadData}
              barSize={20}
              maxBarSize={20}
              margin={{ top: 0, right: 0, left: -40, bottom: 0 }}
            >
              <CartesianGrid vertical={false} stroke="#f5f5f5" />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#999', fontSize: 11 }}
                interval={0}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#999', fontSize: 12 }}
                domain={[0, 'dataMax + 4']}
              />
              <Tooltip content={CustomTooltip} />
              <Bar dataKey="value" fill="#007bff" radius={[4, 4, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
        )}
        {!loading && noData && (
          <div className="no-data">
            <p>{t('noDataToDisplay')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyUnload;
