import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import '../style.scss';
import { NbBucketsDeactivated } from '../types';

interface Props {
  nbDeactivedBuckets: NbBucketsDeactivated | undefined;
  loading: boolean;
}

const NbDeactivatedBucket: React.FC<Props> = ({ nbDeactivedBuckets, loading }): JSX.Element => {
  const { t } = useTranslation();
  const [deactivatedBuckets, setDeactivatedBuckets] = useState<NbBucketsDeactivated | null>(null);
  const [noData, setNoData] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(1);

  useEffect(() => {
    if (nbDeactivedBuckets) {
      setNoData(false);
      if (nbDeactivedBuckets.nbTotal === 0) {
        setNoData(true);
        return;
      }
      setDeactivatedBuckets(nbDeactivedBuckets);
    }
  }, [nbDeactivedBuckets]);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 7) * cos;
    const sy = cy + (outerRadius + 7) * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 16;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontSize="14">
          {t(payload.reason)}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={activeIndex === payload.index ? '#f08234' : '#f08234'}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke="#f08234" fill="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey + 4}
          textAnchor={textAnchor}
          fill="#333"
          fontWeight="600"
        >
          {` ${value}`}
        </text>
      </g>
    );
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(undefined);
  };

  return (
    <div className="componentBase nb-deactivated-bucket-container">
      <h5 className="m-3">{t('NbDeactivatedBucket')}</h5>
      {loading && (
        <div style={{ marginLeft: '4.5vw', marginTop: '7.5vh' }}>
          <FontAwesomeIcon icon={faSpinner} style={{ fontSize: '38px', color: 'gray' }} spin />
        </div>
      )}
      {!loading && noData && (
        <div className="no-data">
          <p>{t('noDataToDisplay')}</p>
        </div>
      )}
      {!loading && !noData && (
        <>
          <ResponsiveContainer width="100%" height="68%">
            <PieChart onMouseLeave={onPieLeave}>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={deactivatedBuckets?.typesBuckets.map((bucket) => ({
                  ...bucket,
                  value: bucket.number,
                }))}
                cx="50%"
                cy="50%"
                innerRadius={57}
                outerRadius={73}
                fill="#0071e9"
                dataKey="value"
                onMouseEnter={onPieEnter}
              />
            </PieChart>
          </ResponsiveContainer>
          <div className="nb-deactivated-bucket-total">
            Total : {deactivatedBuckets?.nbTotal} {t('deactivatedBuckets')}
          </div>
        </>
      )}
    </div>
  );
};

export default NbDeactivatedBucket;
